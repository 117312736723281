import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
      <div className="container15">
        <h1 style={{marginLeft:"60px",fontSize:"60px"}}>Privacy Policy</h1>
      </div>
      <div className="container1">
        <p style={{width:"80%",marginLeft:"3%"}}>
          "Sahaj Therapy", "Website", "We", "Our" or "Us" is the owner of https://www.sahajtherapy.com/ and the Sahaj Therapy mobile application (the URLs and mobile application are together and individually referred to as "Website"). The terms 'You' or 'Your' refer to You as the user (whether You are a Registered User, Unregistered user, or HCPRs (Health Care Professionals)) of the Website or of the services offered by the Website ("Service").
        </p>
        <p  style={{width:"80%",marginLeft:"3%"}}>
          As mentioned earlier, Terms of Use, the Privacy Policy, the Additional Terms of Use for HCPRs, together with any other policies which may be applicable to specific portions of the Website and any disclaimers which may be present on the Website are referred to as "the Agreement" therefore, the above mentioned must be read together.
        </p>
        <p  style={{width:"80%",marginLeft:"3%"}}>
          Sahaj Therapy is committed to Your privacy. This privacy policy ("Privacy Policy") applies to the collection, use, storage, processing, disclosure, and transfer of Your Information (defined below) when You use the Website and/or the Services.
        </p>
      </div>
      <hr />
      <div className="container2">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>WHY THIS PRIVACY POLICY?</h3>
        <p style={{ color: 'grey', textAlign:"start",marginLeft:"20px"}}>
          1. This Privacy Policy is published in compliance with, inter alia:
        </p>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>Section 43A of the Information Technology Act, 2000;</li>
          <br />
          <li>Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI Rules”);</li>
          <br />
          <li>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 and other applicable laws of India.</li>
        </ol>
      </div>
      <div className="container3">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>2.This Privacy Policy states the following:</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>The type of information collected from the Users, including Personal Information and Sensitive Personal Data or Information relating to the Users;</li>
          <br />
          <li>The purpose, means, and modes of collection, usage, processing, retention, and destruction of such information; and</li>
          <br />
          <li>How and to whom Knee Xpert will disclose such information.</li>
        </ol>
      </div>
      <div className="container4">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>2.CONSENT</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>By clicking on the "I accept' button at the end of the page containing the Terms of Use or by providing us Your Personal Information and/or Personal Sensitive Data or Information or by making use of the features provided by the Website, You hereby consent to the collection, storage, processing, disclosure and transfer of Your Information in accordance with the provisions of this Privacy Policy.</li>
          <br />
          <li>You acknowledge that You are providing Your Personal Information and/or Personal Sensitive Data or Information out of Your free will.</li>
          <br />
          <li>You have the option not to provide us the Personal Information and/or Personal Sensitive Data or Information sought to be collected. You will also have an option to withdraw Your consent at any point, provided such withdrawal of consent is intimated to us in writing at support@sahajkneexpert.com. If You do not provide us Your Personal Information and/or Personal Sensitive Data or Information and if You withdraw the consent at any point in time, We shall have the option not to fulfill the purpose for which the said Personal Information was sought and we may restrict You from using the Website.</li>
        </ol>
      </div>
      <div className="container5">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>3.CHANGES TO THE PRIVACY POLICY</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>The privacy policy is subject to be reviewed and updated periodically There is a tab at the end of the Privacy Policy which indicates when the Privacy Policy was last updated.</li>
          <br />
          <li>The Website holds the sole discretion to amend the present privacy policy and/or part of the Agreement from time to time and as per the compliance of the applicable laws of India. The mere access of the Website implies Inferred Consent on the part of the User to each and every part/clause of the Agreement.</li>
          <br />
        </ol>
      </div>
      <div className="container6">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>4.WHAT INFORMATION IS COLLECTED FROM THE USER?</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>The kind of Personal Information that the User provides at will and is collected by the Website includes Personal Information and/or Sensitive Personal Data or Information (“Personal Information”) and Non-Personal Information. Personal Information and Non-Personal Information are collectively referred to as "Collected Information"</li>
          <br />
          <li>Personal Information is data collected that can be used to uniquely identify or contact the User. Personal Information for the purposes of this Privacy Policy shall include, but not be limited to:
            <ol style={{ marginLeft:40, color: 'grey',textAlign:"start",paddingTop:"10px" }}>
                <li style={{paddingTop:"20px" }}>Name;</li>
                <li style={{paddingTop:"20px" }}>Birth Date;</li>
                <li style={{paddingTop:"20px" }}>Email address;</li>
                <li style={{paddingTop:"20px" }}>Gender;</li>
                <li style={{paddingTop:"20px" }}>financial information such as Bank account or credit card or debit card or other payment instrument details (only if required) ;</li>
                <li style={{paddingTop:"20px" }}>physical, physiological, and mental health condition;</li>
                <li style={{paddingTop:"20px" }}>medical records and history;</li>
                <li style={{paddingTop:"20px" }}>Login ID and password,</li>
                <li style={{paddingTop:"20px" }}>Location,</li>
            </ol>
          </li>
          <br />
          <li>We may also seek permission to use the camera, microphone, and phonebook/contact data at points where required.</li><br></br>
          <li>We may also collect information other than Personal Information from You through the Website when You visit and /or use the Website. Such information may be stored in server logs. Such Non-Personal Information would not assist us to identify You personally. Such Non-Personal Information may include but is not limited to:
          <ol style={{ marginLeft:40, color: 'grey',textAlign:"start",paddingTop:"10px" }}>
                <li style={{paddingTop:"20px" }}>Your usage details such as time, frequency, duration and pattern of use, features used and the amount of storage used,</li>
                <li style={{paddingTop:"20px" }}>Master and transaction data and other data stored in Your user account,</li>
                <li style={{paddingTop:"20px" }}>Internet Protocol address, browser type, browser language, referring URL, files accessed, errors generated, time zone, operating system and other visitor details collected in Our log files,</li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="container7">
        <h3 style={{  color: '#264796',textAlign:"start" }}>5.HOW DO WE COLLECT INFORMATION?</h3>
        <p style={{ color: 'grey', textAlign:"start",marginLeft:"20px"}}>
          1. The methods by which We collect Your Information include but are not limited to the following:
        </p>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>When You register on Our Website, or</li>
          <br />
          <li>When You create a profile on Our Website or as part of Services, or</li>
          <br />
          <li>When You provide Your Information to us, or</li>
          <br />
          <li>When You use the features on Our Website and/or when You use Services, or</li>
          <br />
          <li>When You access Website or Services, or</li>
          <br />
          <li>By use of cookies (discussed below).</li>
        </ol>
      </div>
      <div className="container8">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>6.HOW IS THE INFORMATION COLLECTED IS USED?</h3>
        <p style={{ color: 'grey', textAlign:"start",marginLeft:"20px"}}>
          1. We collect Your Information for various purposes, including the following:
        </p>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>In order to enable You to use the Website and utilize the Services. For instance, You may provide Your health-related information on the Website in order to consult privately with an HCPRs or to book a Home Visit or to get an Expert Opinion/Second Opinion, or You may provide Your financial information in order to process a payment which You need to make on the Website.</li>
          <br />
          <li>to respond to Your inquiries;</li>
          <br />
          <li>to provide You with information about products and services available on the Website;</li>
          <br />
          <li>to personalize Your experience on the Website;</li>
          <br />
          <li>to help You address Your problems incurred on the Website including addressing any technical problems;</li>
          <br />
          <li>for proper administering of the Website;</li><br/>
          <li>to conduct internal reviews and data analysis for the Website (e.g., to determine the number of visitors to specific pages within the Website);</li>
          <br />
          <li>to improve the services, content, and advertising on the Website;</li>
          <br />
          <li>to protect the integrity of the Website; and</li>
          <br />
          <li>to respond to judicial process and provide information to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law.</li>
          <br />
          <li>to conduct analytical studies on various aspects including user behavior, user preferences, etc.</li>
          <br />
          <li>to conduct analytical studies on various aspects including user behavior, user preferences, etc.</li>
          <br />
        </ol>
      </div>
      <p style={{textAlign:"start",marginLeft:70,color:"grey"}}>(above uses collectively referred to as "Purpose(s)")</p>
      <div className="container9">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>1.HOW INFORMATION MAY BE SHARED AND TRANSFERRED?</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>We may exchange, transfer, share, part with all or any of Your Information, across borders and from Your country to any other countries across the world with Our affiliates/agents / third party service providers/partners/banks and financial institutions for the Purposes specified under this Privacy Policy or as may be required by applicable law of India or in case of sale, acquisition, merger or bankruptcy involving Knee Xpert.</li>
          <br />
          <li>You acknowledge that some countries where We may transfer Your Information may not have data protection laws that are as stringent as the laws of Your own country.</li>
          <br />
          <li>You acknowledge that it is adequate that when Knee Xpert transfers Your Information to any other entity within or outside Your country of residence, Knee Xpert will place contractual obligations on the transferee which will oblige the transferee to adhere to the provisions of this Privacy Policy.</li>
        </ol>
      </div>
      <div className="container10">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>2.PROTECTION AND NECESSARY DISCLOSURE OF PERSONAL INFORMATION</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>The Website is committed to uphold the privacy of the Users as per the applicable laws of India amended from time to time.</li>
      
          <li>The protection of the User Information and Disclosure would only be as per the Agreement and the provisions of Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011.
            <br></br><a href='https://www.wipo.int/edocs/lexdocs/laws/en/in/in098en.pdf' alt=" ">Read the rule </a></li>
          <br />
        </ol>
      </div>
      <div className="container11">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>3.LINKS</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>The Websites may contain links to other third party sites. The third-party sites are not under the control of Knee Xpert. Please note that Knee Xpert is not responsible for the privacy practices of such third party sites. Knee Xpert encourages You to be aware when You leave the Websites and to read the privacy policies of each and every third party site that collects personal information. If You decide to access any of the third-party sites linked to the Websites, You do this entirely at Your own risk. Any links to any partner of the Websites is the responsibility of the linking party, and Knee Xpert shall not be responsible for notification of any change in name or location of any information on the Websites.</li>
        </ol>
      </div>
      <div className="container12">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>4.CORRECTING INACCURACIES OR UPDATING PERSONAL INFORMATION</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>If Your Personal Information changes, or if You need to update or correct Your Personal Information or have any grievance with respect to the processing or use of Your Personal Information, for any reason, You may send updates and corrections to us at the provided link and We will take all reasonable efforts to incorporate the changes within a reasonable period of time. If Your Personal Information is stored as part of Your profile on the Website, You can update Your profile on the profile pages of the Websites.</li>
          <br />
          <li>You are not allowed to use the Website or the Services of the Website if any of the terms of this Privacy Policy are not in accordance with the applicable laws of India or Your country.</li>
        </ol>
      </div>
      <div className="container13">
        <h3 style={{ margin: 0, color: '#264796',textAlign:"start" }}>5.COMPLIANCE WITH THE LAWS</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>You are not allowed to use the Website or the Services of the Website if any of the terms of this Privacy Policy are not in accordance with the applicable laws of India or Your country.</li>
        </ol>
      </div>
      <div className="container14">
        <h3 style={{ margin: 0, color:'#264796',textAlign:"start" }}>6.GRIEVANCE OFFICER</h3>
        <br></br>
        <ol style={{ marginLeft: 50, color: 'grey',textAlign:"start" }}>
          <li>We have appointed a Grievance Officer to address any concerns or grievances that You may have regarding the processing of Your Personal Information. If You have any such grievances, please write to Our Grievance Officer at support@sahajkneexpert.com and Our officer will attempt to resolve Your issues in a timely manner.</li>
        </ol>
      </div>
      <p style={{textAlign:"start",marginLeft:70,color:"grey" ,fontSize:"16px"}}>DISCLAIMER: Knee Xpert reserves the right, in its sole discretion, to change, modify, add or delete portions of the terms of this Privacy Policy at any time.</p><br></br>
    </div>
  );
}

export default PrivacyPolicy;