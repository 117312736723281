import React from "react";
import { Box } from "@mui/material";

function Knee() {
  return (
    <>
      <div>
        <div className="container15">
          <h1 style={{ marginLeft: "60px", fontSize: "60px" }}>Knee Anatomy</h1>
        </div>
        <div className="container1">
          <p style={{ width: "100%", marginLeft: "3%" }}>
            The knee joint has three parts. The thigh bone (the femur) meets the
            large shin bone (the tibia) to form the main knee joint. This joint
            has an inner (medial) and an outer (lateral) compartment. The
            kneecap (the patella) joins the femur to form a third joint, called
            the patellofemoral joint. The patella protects the front of the knee
            joint. Other than that tendon, ligament and muscles plays a vital
            role in functioning of knee joint.
          </p>
          <p style={{ width: "96%", marginLeft: "3%" }}>
            Articular cartilage coats the ends of the bones in the knee joint to
            provide a smooth gliding surface so the bones in the joint move
            fluidly against each other and the “The crescent-shaped pad of
            cartilage called the meniscus functions as a joint stabilizer and
            provides a cushion between the weight-bearing bone surfaces in the
            knee.
          </p>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gridGap: "100px",
            padding: "50px",
            alignItems: "initial",
        
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src="https://www.sahajtherapy.com/files/Knee%20Anatomy.png"
              style={{ width: "340px", height: "500px" }}
              alt=""
            />
          </div>
          <div>
            <div style={{ textAlign: "center" }}>
              <img
                src="	https://www.sahajtherapy.com/files/MCTD_1.png"
                style={{ width: "340px", height: "500px" }}
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <Box
            sx={{
              background: "#d7edf2",
              width: "100%",
              textAlign: "center",
              maxWidth: "100%",
            }}
          >
            <p
              style={{
                color: "#1db0d0",
                paddingTop: "200px",
                fontFamily: "lato",
                fontSize: "clamp(1.5rem, 5vw, 3rem)",
                fontWeight: "500",
                margin: "0",
                marginTop: "150px",
              }}
            >
              4 Major Reasons of Knee Pain
            </p>
            <div style={{alignItems:"center"}} className="card-container">
          <div style={{borderRadius:"15px",backgroundColor:"white",marginBottom:"100px"}} className="card-image-container">
            <div className="card-text">
              <h3 style={{ fontFamily: "fantasy" }}>Week Knee / Leg Muscles</h3>
              <p>
                After the age of 40 years it is found in different research work
                that every 10 years there is around 8 to 10 % muscle loss. Due
                to week muscles the body weight get directly transferred to the
                knee bones which becomes the reason of knee pain. Symptoms often
                include pain, swelling, and difficulty in walking.
              </p>
            </div>
          </div>

          <div style={{ height: "50%",borderRadius:"15px",backgroundColor:"white",marginBottom:"100px" }} className="card-image-container">
            <div className="card-text">
              <h3 style={{ fontFamily: "fantasy" }}>
                Tendon/ Ligament Injury or sprain
              </h3>
              <p>
                Inflammation of the tendons/ ligament may result from overuse or
                due to injury in them that can be treated by Orthopaedic surgeon
                or by physiotherapist.
              </p>
            </div>
          </div>

          <div style={{borderRadius:"15px",backgroundColor:"white",marginBottom:"100px"}} className="card-image-container">
            <div className="card-text">
              <h3 style={{ fontFamily: "fantasy" }}>
                Torn or damaged Cartilage
              </h3>
              <p>
                Cartilage damage is the reason of knee pain in more than 80%
                case which enhances with the age and ignorance of same may leads
                to complicated surgeries in near future. If it is not ignored
                and treated in time, not only the progression disease can be
                stopped even it can be reversed.
              </p>
            </div>
          </div>
          <div style={{borderRadius:"15px",backgroundColor:"white",marginBottom:"100px"}} className="card-image-container">
            <div className="card-text">
              <h3 style={{ fontFamily: "fantasy" }}>
                Deformities in Knee Joint / Legs
              </h3>
              <p>
                Leg deformities are musculoskeletal abnormalities that can be
                congenital (something you are born with), developmental, or
                acquired as the result of fracture, infection, arthritis or
                tumor. Becomes the reason of joint pain due to early to damage
                of cartilage.
              </p>
            </div>
          </div>
        </div>
          </Box>
        </div>
      
      </div>
      <br></br>
      <br></br>
    </>
  );
}

export default Knee;
