import React from 'react';
import { Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';


function Avnpage() {

  const navigate = useNavigate();


  const HandleClick = () => {
    navigate("/register")
  }
  return (
    <div style={{ width: "100%" }}>
      <div className="consultation-wrapper">
        <h1 className="consultation-title">Book Free Online Consultation</h1>
        <button style={{marginTop:"20px"}} className="consultation-btn" onClick={HandleClick}>Book Appointment Now</button>
        {/* <p className="consultation-text">Click here to book free online Consultation</p> */}
      </div>




      <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", gridGap: "1px", padding: "70px", alignItems: "center" }}>
        <div style={{ textAlign: "left" }}>
          <img src='https://www.sahajtherapy.com/files/Hip-joint-AVN-overview.jpeg' style={{ width: "50%",marginLeft:"30px" }} alt='' />
        </div>
        <div style={{ marginLeft: "-10px" }}>
          <h1 style={{ marginTop: "40px" }}>Avascular Necrosis</h1>
          <p style={{ fontSize: "16px", color: "#4c5a67", width: "99%", marginLeft: "1%" }}>Avascular means blood flow loss and necrosis indicate cell death or tissue death. When a bone dies because of a lack of blood supply, avascular necrosis or osteonecrosis is identified. Often known are the following names – aseptic necrosis, bone ischemic necrosis, and bone infarction.</p>
          <p style={{ fontSize: "16px", color: "#4c5a67", width: "99%", marginLeft: "1%" }}>This disease is very common in persons of age between 30 and 60 years. Sometimes it also affects the children, commonly those who are on cancer therapy. AVN can affect the hip, shoulder, wrist, ankle, etc. Femoral head of hip joint most commonly affected.</p>
          <p style={{ fontSize: "16px", color: "#4c5a67", width: "99%", marginLeft: "1%" }}>The interruption of supply of blood to the joint of the hip leads to the damage or bone cell death of femur head (thigh bone). Bone collapse or bone damage is because of ignorance or delay in getting the treatment.</p>
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(380px, 1fr))", gridGap: "20px", padding: "50px" }}>
        <div style={{ marginLeft: "25px" }}>
          <h1 style={{marginBottom:"40px"}}>Stages of AVN of Hip</h1>
          <ul style={{ textAlign: "Start" }}>
            <li>Stage 1 - The x-ray is normal. But MRI shows mild changes (cell death)</li>
            <li>Stage 2 - Hip X-ray shows clearly visible changes. The shape of the femoral head (ball) is intact.</li>
            <li>Stage 3 - There are changes in the shape of the ball of the femur.</li>
            <li>Stage 4 - There is clear evidence of femoral head collapse and cartilage damage in the x-ray.</li>
          </ul>
        </div>
        <div style={{ textAlign: "center", marginLeft: "-30px" }}>
          <img src='https://www.sahajtherapy.com/files/Hip-joint-AVN-stages%20(1).jpeg' alt='' />
        </div>
      </div>
      <div style={{ textAlign: "left" }}>
        <h1 style={{ marginLeft: "70px" }}>Causes</h1>
      </div>
      {/* <hr style={{ width: "90%", marginLeft: "10px" }}></hr> */}

      <div style={{ width: "100%" }}>
        <p style={{ fontSize: "18px", marginLeft: "70px" }}>A non-supply of blood to the bone is the most common cause of avascular necrosis.</p>


        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))" }}>
          <div className="grid-item" style={{ marginLeft: "60px", marginTop: "30px" }}>
            <Card style={{ height: "200px",width:"90%" }}>
              <h4 style={{ textAlign: "Start", marginLeft: "2%", marginTop: "5%" }}>Following are some more causes :</h4>
              <ul style={{ textAlign: "Start" }}>
                <li>
                  Fracture of bone
                </li>
                <li>
                  Dislocation of hip
                </li>
                <li>
                  Trauma or injury to the joint or bone
                </li>
              </ul>
            </Card>
          </div>
          <div className="grid-item" style={{ marginLeft: "60px", marginTop: "30px" }}>
            <Card style={{ height: "200px",width:"90%",marginRight:"5%" }}>
              <h4 style={{ textAlign: "Start", marginLeft: "2%", marginTop: "5%" }}>Following are some more causes :</h4>
              <ul style={{ textAlign: "Start", }}>
                <li>
                  Fat deposition in the blood vessels
                </li>
                <li>
                  Other disease like anemia
                </li>
              </ul>
            </Card>
          </div>
        </div>

        <div><h1 style={{ marginLeft: "60px",marginTop:"30px",marginBottom:"40px" }}>Risk factors</h1></div>
        {/* <hr style={{ width: "90%", marginLeft: "10px" }}></hr> */}
        <div style={{ textAlign: "Start", marginLeft: "10px" }}>
          <ul style={{ textAlign: "Start", marginLeft: "40px", color: "#4C5A67" }}>
            <li>Drinking alcohol</li>
            <li>Trauma</li>
            <li>High dose of corticosteroids</li>
            <li>Chemotherapy</li>
            <li>Some diseases like sickle cell anemia, Gaucher’s disease, HIV</li>
          </ul>
        </div>


        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", gridGap: "20px", padding: "50px" }}>
          <div style={{ textAlign: "Start", marginLeft: "10px" }}>
            <h1 style={{ marginLeft: "15px",marginBottom:"40px" }}>Symptoms </h1>
            <ul style={{ textAlign: "Start" }}>
              <li>Pain around the affected joint</li>
              <li>Pain is mild to severe</li>
              <li>Pain in the groin, pain can spread towards the knee and buttocks</li>
              <li>Pain aggravates while putting weight on the affected hip joint</li>
              <li>Restricted joint movement due to severe pain</li>
              <li>Limping</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginLeft: "-40px" }} >
            <img src='https://www.sahajtherapy.com/files/Hip-joint-AVN-stages%20(1).jpeg' alt='' />
          </div>
        </div>
        <div style={{ textAlign: "left" }}>
          <h1 style={{ marginLeft: "60px",marginBottom:"40px" }}>Diagnosis</h1>
        </div>

        {/* <hr style={{ width: "90%", marginLeft: "10px" }}></hr> */}
        <div style={{ textAlign: "Start", marginLeft: "10px" }}>
          <ul style={{ textAlign: "Start", marginLeft: "40px", color: "#4C5A67" }}>
            <li>X-rays: To see the changes in the joint and bone, and to see the stage of AVN. The initial x-ray might be normal.</li>
            <li>MRI and CT scan: Detailed joint images, more specific. Also, shoes if there is any soft tissue injury.</li>
            <li>Bone scan: Helps visualizing inside the bones.</li>

          </ul>

        </div>
        <br></br>
        <h1 style={{ marginLeft: "60px" }}>Treatment</h1>
        <p style={{ fontSize: "25px", color: "black",marginLeft:"60px" }}>Conservative</p>
        {/* <hr style={{ width: "90%", marginLeft: "10px" }}></hr> */}
        <div style={{ textAlign: "Start", marginLeft: "10px" }}>
          <ul style={{ textAlign: "Start", marginLeft: "40px", color: "#4C5A67" }}>
            {/* <p style={{ fontSize: "25px", color: "black",marginRight:"90px" }}>Conservative</p> */}
            <li><span style={{ fontSize: "20px", color: "black" }}>Medications :<br></br> </span> Anti-inflammatory drugs, blood thinners, drugs to reduce the cholesterol level, etc.</li>
            <li>As far as you can, stop placing weight on the affected joint. take rest and use crutches for offloading from your hip joint</li>
            <li>Exercises under the guidance of a physiotherapist to maintain joint mobility and to enhance the muscle strength.</li>
            <li>Bone healing instigated by electrical impulses</li>
            <li><span style={{ fontSize: "20px", color: "black" }}>Surgery : </span> Anti-inflammatory drugs, blood thinners, drugs to reduce the cholesterol level, etc.</li>
            <li><span style={{ fontSize: "20px", color: "black" }}>Regenerative medicine : </span> Anti-inflammatory drugs, blood thinners, drugs to reduce the cholesterol level, etc.</li>
            <li><span style={{ fontSize: "20px", color: "black" }}>Regenerative medicine : </span> It is a novel regenerative
              treatment that is able to treat Avascular necrosis of the hip. Early intervention<br></br>
              can help arrest the progression of AVN. This procedure performed under local anesthetic agents.</li>
          </ul>

        </div>
        <div className='grid-container20' style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))', padding: '50px', gap: '20px' }}>
          <div className='grid-item25'>
            <h1 style={{ textAlign: 'start', marginLeft: "20px",marginBottom:"35px" }}>SVF therapy:</h1>
            <ul style={{ textAlign: 'start' }}>
              <li>The doctor draws 200 ml of fat from the abdomen within 40 minutes without the use of enzymes, chemicals, or any animal product.</li>
              <li>Derivation of the SVF done using Australian patented technology.</li>
              <li>This derived SVF injected into the affected knee joint.</li>
              <li>In India and around the globe, thousands of patients have been treated with this technique.</li>
              <li>It offers a minimally invasive solution for patients suffering from Hip pain due to early AVN.</li>
            </ul>
          </div>
          <div className='grid-item4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src='https://www.sahajtherapy.com/files/Hip-joint-AVN-regenerative-medicine.jpeg' alt='' style={{ width: "420px", }}></img>
          </div>
        </div>




        <div className='grid-container19' style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))" }}>
          <div className="grid-item3" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "17px" }}>
            <img src='https://www.sahajtherapy.com/files/Hip-replacement-treatment.jpeg' alt='' />
          </div>
          <div className="grid-item27" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", padding: "0 20px",marginBottom:"50px" }}>
            <h1 style={{ textAlign: "start", marginTop: "30px", marginLeft: "40px" ,marginBottom:"40px"}}>Hip Replacement</h1>
            <ul style={{ textAlign: "start", listStyleType: "disc", marginLeft: "25px" }}>
              <li><span style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}>Decompression of core: </span> The inner layer of the bone is removed surgically. Apart from just alleviating the pain, the additional space within the bone provides stimulus for the production of new healthy bone and blood vessels.</li>
              <li><span style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}>Bone transplant (graft): </span> A part of the healthy bone from another part of the body is taken and is grafted over the area which is damaged by avascular necrosis.</li>
              <li><span style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}>Reshaping bone (osteotomy): </span> A wedge-shaped bone is cut either from above or below the joint. This helps in the distribution of weight off from the damaged bone. Bone reshaping defers the joint replacement surgery.</li>
              <li><span style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}>Complete replacement of the joint: </span> In case of complete joint damage (Bone collapsed), stage IV AVN and when other treatment procedures are not responding, a total joint replacement is performed.</li>
            </ul>
          </div>
        </div>




      </div>
    </div>

  );
}

export default Avnpage;