import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Button } from "@mui/material";

const images = [
  "https://www.sahajtherapy.com/files/Slider1.jpg",
  "https://www.sahajtherapy.com/files/Slider%201.jpg",
];

const Home = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const container = useRef(null);
  const [displayFirstImage, setDisplayFirstImage] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplayFirstImage((displayFirstImage) => !displayFirstImage);
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("./VideoImage.json"),
    });

    return () => {
      anim.destroy();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((currentImage) => (currentImage + 1) % images.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  return (
    <div style={{ width: "100%"}}>
      <div
        style={{ position: "relative", maxWidth: "100vw", overflow: "hidden" }}
      >
        {images.map((image, index) => (
          <div key={index} style={{ position: "relative" }}>
            <img
              style={{
                width: "100%",
                height: "auto",
                display: currentImage === index ? "block" : "none",
              }}
              src={image}
              alt={`slider${index + 1}`}
            />
            {/* {currentImage === index && (
  <button
    style={{
      position: "absolute",
      bottom: "17%",
      left: "15%",
      backgroundColor: "rgb(36, 144, 239)",
      color: "white",
      padding: "10px",
      border: "none",
      borderRadius: 10,
      cursor: "pointer",
      fontSize: "0.8rem", // increased font size for mobile
      width: "200px",
      maxWidth: "100%",
    }}
  >
    Book Online Free Consultation
  </button>
)} */}
          </div>
        ))}
        <div
          style={{
            position: "absolute",
            bottom: "5%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {images.map((image, index) => (
            <button
              key={index}
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: currentImage === index ? "white" : "grey",
                margin: "0 5px",
              }}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          position: "relative",
          height: "auto",
          minHeight: "250px",
          backgroundColor: "#eef0f2",
          paddingTop: "60px",
          // marginTop: "10px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "clamp(1.5rem, 5vw, 2rem)",
            margin: "0 20px",
          }}
        >
          Take the First Step towards a Pain-Free Life
        </h1>
        <p
          style={{
            textAlign: "center",
            fontSize: "clamp(1rem, 3vw, 1.2rem)",
            margin: "10px 80px",
          }}
        >
          Our regenerative medicine treatments using autologous adipose-derived
          cellular grafting have helped countless patients with early
          osteoarthritis and AVN. Don't let chronic pain and limited mobility
          hold you back any longer. Take the first step towards a pain-free life
          by scheduling a consultation with our team today.
        </p>
        <button
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "10px 10px",
            border: "none",
            borderRadius: 10,
            cursor: "pointer",
            fontSize: "clamp(0.8rem, 3vw, 0.9rem)",
            width: "clamp(200px, 80vw, 230px)",
            transition: "background-color 0.2s ease-in-out",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            marginBottom: "20px",
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#0066CC";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "blue";
          }}
        >
          Book Online Free Consultation
        </button>
      </div>
      <div
        className="container"
        style={{ width: "80%", marginLeft: "9%" ,marginTop:"20px"}}
        ref={container}
      ></div>

      <div style={{marginBottom:"5px",}}>
        <h1 style={{textAlign:"center",marginTop:"30px" }}>Our Services</h1>
        
        <div className="card-container">
          <div className="card-image-container">
            <img src="Our services 1.jpg" alt="Service 1" />
            <div className="card-text">
              <h3>Autologous Grafting</h3>
              <p>
                Stormal Vascular Fraction and Platlet rich plasma (PRP) done in
                same surgical sitting
              </p>
            </div>
          </div>

          <div className="card-image-container">
            <img src="Our services 2.jpg" alt="Service 2" />
            <div className="card-text">
              <h3>Rehabilition</h3>
              <p>
                A Specilized rehabilition program is design to meet the needs...
              </p>
            </div>
          </div>

          <div className="card-image-container">
            <img src="Our services 3.jpg" alt="Service 1" />
            <div className="card-text">
              <h3>Deformity correction</h3>
              <p>
                Comman Deformilities in knee such as Varus and Valgus
                deformilities
              </p>
            </div>
          </div>

          <div className="card-image-container">
            <img src="Our services 4.jpg" alt="Service 2" />
            <div className="card-text">
              <h3>Councelling & Consultation</h3>
              <p>
                Comman deformilities in knee such as Varus and Valgus
                deformilities
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: "20px",
          maxWidth: "100%",
          marginBottom: "30px",
        }}
      >
        <h3
          style={{
            textAlign: "start",
            fontSize: "clamp(1rem, 2vw, 3rem)",
            marginLeft: "10%",
            padding: "2px 0",
          }}
        >
          SVF Technology
        </h3>
        {/* <hr
          style={{
            width: "80%",
            margin: "50px auto",
            border: "none",
            borderTop: "2px solid #ccc",
          }}
        /> */}
        <p
          style={{
            fontSize: "clamp(1rem, 2vw, 1.1rem)",
            width: "80%",
            // padding: "20px 0",
            textAlign: "justify",
            marginLeft: "10%",
          }}
        >
          Sahaj Therapy® is based on a patented SVF technology which treats the
          root cause of Knee Pain due to early Arthritis. The innovative
          treatment is safe and does not use any chemical, enzyme or a cell
          culture.
          <br />
          <br />
          The technique utilizes specialized cells obtained from patients own
          body through a minimal manipulation of autologous adipose derived
          stromal vascular fraction, performed in the same surgical sitting.
        </p>
      </div>
      <div className="inventor-container">
        {displayFirstImage ? (
          <>
            <div className="inventor-info">
              <h2>Why Choose Us</h2>
              <p>
                Sahaj Therapy®: Complete Solution For Your Knee Pain.Sahaj
                Therapy ® offers a biological solution to a biological problem
                helping the body to heal by itself through a unique mechanism of
                action. The futuristic technology works on modern regenerative
                medicine offering a comprehensive and single-window solution for
                Joint Pain. For more information Contact us
              </p>
              <Button  variant="contained"
                color="primary"
                type="submit" style={{ backgroundColor: "blue",
                color: "white",
                // padding: "10px 10px",
                border: "none",
                borderRadius: 10,
                cursor: "pointer",
                fontSize: "clamp(0.8rem, 3vw, 0.9rem)",
                width: "clamp(200px, 80vw, 230px)",
                transition: "background-color 0.2s ease-in-out",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                marginBottom: "20px",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0066CC";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "blue";
              }}>More....</Button>
            </div>
            <div className="inventor-image">
              <img
                src="https://www.sahajtherapy.com/files/Carousel%202.jpg"
                alt="Dr. Thomas"
              />
            </div>
          </>
        ) : (
          <>
            <div className="inventor-info">
              <h2>Meet the Inventor</h2>
              <p>
                Dr. Thomas has had over 20 years of experience as a medical
                scientist. He spent more than a decade on cell research and
                clinical translation. His research contributions have been
                recognized by his seminal work in cancer with over 25 journal
                articles, book chapters, and awards. Dr. Thomas has been a
                reviewer for both national and international granting bodies and
                journals. Dr. Thomas is an Executive Member of the Australian
                Autologous Cell Therapy (AACT) Consortium that is developing an
                industry-specific voluntary code of conduct to encourage
                responsible use of the TGA Exclusion order by clinical,
                industry, and research member groups.
              </p>
              <Button  variant="contained"
                color="primary"
                type="submit" style={{ backgroundColor: "blue",
                color: "white",
                // padding: "10px 10px",
                border: "none",
                borderRadius: 10,
                cursor: "pointer",
                fontSize: "clamp(0.8rem, 3vw, 0.9rem)",
                width: "clamp(200px, 80vw, 230px)",
                transition: "background-color 0.2s ease-in-out",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                marginBottom: "20px",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0066CC";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "blue";
              }}>More....</Button>
            </div>
            <div className="inventor-image">
              <img
                src="https://www.sahajtherapy.com/files/Carousel%201.jpg"
                alt="Dr. Thomas"
              />
            </div>
          </>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          marginBottom:"10px"
        }}
      >
        <Box
          sx={{
            background: "#afcce9",
            borderRadius: "20px",
            width: "90%",
            padding: "30px",
            textAlign: "center",
            maxWidth: "1200px",
          }}
        >
          <p
            style={{
              color: "#2490ef",
              paddingTop: "30px",
              fontFamily: "lato",
              fontSize: "clamp(2rem, 7vw, 4rem)",
              fontWeight: "500",
              margin: "0",
            }}
          >
            Don't Ignore or Drag Knee Pain.
          </p>
          <p
            style={{
              paddingTop: "20px",
              fontSize: "clamp(1.5rem, 5vw, 3rem)",
              fontFamily: "lato",
              margin: "0",
            }}
          >
            Connect with us.
          </p>
          <Slider
            size="small"
            style={{ width: "200px", color: "green", margin: "20px auto" }}
          />
          <p
            style={{
              paddingTop: "30px",
              fontSize: "clamp(1.5rem, 5vw, 3rem)",
              fontFamily: "lato",
              margin: "0",
            }}
          >
            Give missed call or
          </p>
          <p
            style={{
              paddingTop: "25px",
              fontSize: "clamp(1.5rem, 5vw, 3rem)",
              fontFamily: "lato",
              margin: "0",
            }}
          >
            WhatsApp on <span style={{ color: "#2490ef" }}> 82230 83555.</span>
          </p>
          <Button
            style={{
              backgroundColor: "lightBlue",
              color: "gray",
              fontSize: "clamp(1rem, 3vw, 1.2rem)",
              borderRadius: "20px",
              border: "2px solid #d3d9df",
              padding: "8px",
              marginTop: "30px",
            }}
          >
            Book Online Free Consultation
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Home;

// https://www.sahajtherapy.com/files/Carousel%202.jpg
// https://www.sahajtherapy.com/files/Carousel%201.jpg

{
  /* <div className="container1" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
  <div className="left-box" style={{ padding: "16px", width: "100%", maxWidth: "700px", textAlign: "center" }}>
    <h2 style={{ fontSize:"30px", marginBottom: "10px" }}>Why Choose Us</h2>
    <p style={{ fontSize:"16px", textAlign: "left" }}>
      Sahaj Therapy®: Complete Solution For Your Knee Pain. Sahaj Therapy ® offers a biological solution to a biological problem
      helping the body to heal by itself through a unique mechanism of action. The futuristic technology works on modern regenerative
      medicine offering a comprehensive and single-window solution for Joint Pain. For more information Contact us
    </p>
  </div>

  <div className="right-box" style={{ padding: "16px", width: "100%", maxWidth: "700px" }}>
    <img
      src="https://www.sahajtherapy.com/files/Carousel%202.jpg"
      alt="Why Choose Us"
      style={{ display: "block", maxWidth: "100%", height: "auto" }}
    />
  </div>
</div>

<style jsx>{`
  .container1 {
    overflow: hidden;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 768px) {
    .container1 {
      flex-direction: row;
    }
    .left-box {
      flex: 1;
      margin-right: 20px;
    }
    .right-box {
      flex: 1;
    }
  }
`}</style> */
}
