import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import './App.css';
import Avnpage from "./Components/AvnPage"
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import PrivacyPolicy from "./Components/PrivacyPolicy";
import RegistrationForm from './Components/RegistrationForm';
import Footer from "./Components/Footer/Footer";
import Knee from "./Components/KneeAnatomy/knee";
function App() {
  return (
   <>
   <BrowserRouter>
   <Navbar/>
   <Routes>
   <Route path="/" element={<Home/>} />
   <Route path="/register" element={<RegistrationForm/>} /> 
   <Route path="/privacy" element={<PrivacyPolicy/>} /> 
   <Route path="/avn" element={<Avnpage/>} /> 
   <Route path="/knee" element={<Knee/>} /> 
   

   </Routes>
   <Footer/>
   </BrowserRouter>
     </>
  );
}

export default App;
