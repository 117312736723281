import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, Typography, Paper } from "@material-ui/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url('https://www.sahajtherapy.com/files/Slider%2019c210e.jpg')`,
    backgroundRepeat: "no-repeat",
    marginBottom: "18px",
    backgroundSize: "cover",
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "70vh",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  paper: {
    padding: theme.spacing(7),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    width: "100%",
    // maxWidth: "350px",
    alignItems:"center"
  },
  buttonContainer: {
    display: "inline",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(2),
  },
  button: {
    width: "auto",
    height: "40px",
    fontSize: "1rem",
    marginLeft:"16px",
    marginTop:"10px",
    [theme.breakpoints.up("md")]: {
      width: "80px",
    },
  },
  phoneInputContainer: {
    position: "relative",
    marginBottom: theme.spacing(2),
  },
  phoneInput: {
    padding: "10px 50px 10px 10px",
    border: "none",
    borderBottom: "1px solid #ccc",
    width: "100%",
    fontSize: "1.2rem",
    "&:focus": {
      outline: "none",
      borderBottom: "2px solid #000",
    },
  },
  phoneInputFlag: {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    fontSize: "1.5rem",
  },
}));

function RegistrationForm() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [value, setValue] = useState();

  const navigate = useNavigate();

  const HandleData = () => {
    navigate("/");
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // prevent default form submission behavior
    try {
      if (!value || value.length < 14 ) {
        // show an alert if the phone number is not valid
        toast("Please enter a valid mobile number");
        return;
      }
      
      const response = await axios.post(
        "https://develop.sahajtherapy.com/api/method/sahajtherapy.api.new_registration",
        {
          patient_mobile: value,
          patient_name: name,
          city: city,
        }
      );
      if (response.data && response.data.message === "Patient is already registered") {
        // show an alert if the response is successful
        toast("Patient is already registered");
        setCity("");
        setName("");
        setValue("");
      }
      else if (response.data){
        toast("Appointment Booked Successfully");
        setCity("");
        setName("");
        setValue("");
  
      }
  
      console.log(response.data); // handle successful response
  
    } catch (error) {
      console.error(error); // handle error
    }
  };
  
  const handleOnChange = (phoneNumber) => {
    if (phoneNumber && !phoneNumber.includes("-")) {
      // add a hyphen after the country code
      const countryCode = phoneNumber.slice(0, 3);
      const number = phoneNumber.slice(3);
      const formattedPhoneNumber = `${countryCode}-${number}`;
      setValue(formattedPhoneNumber); // set the value state to formatted phone number
    } else {
      setValue(phoneNumber); // set the value state to the phone number
    }
  };

  return (
    <>
    <div style={{}} className={classes.root}>
      <ToastContainer/>
      <Grid item xs={12} md={6} className={classes.grid}>
        <Paper className={classes.paper} style={{width:"80%",alignItems:"center",marginLeft:"50px"}}>
          <Typography variant="h5" align="center" gutterBottom style={{marginBottom:"30px"}}>
            Book Appointment
          </Typography>
          
          <form className={classes.form} onSubmit={handleSubmit} >
            <input
              style={{ padding: "3px 10px",width:"80%" }}
              placeholder="Patient Name"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <PhoneInput
              placeholder="Enter phone number"
              value={value}
              defaultCountry="IN"
              onChange={handleOnChange}
              style={{width:"80%" }}
            />
            <input
              style={{ padding: "3px 10px",width:"80%" , }}
              placeholder="City"
              required
              value={city}
              onChange={(event) => setCity(event.target.value)}
            />

            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={HandleData}
              >
                Discard
              </Button>
            </div>
          </form>
        </Paper>
      </Grid>
      
    
    </div>
    <br></br>
    </>
   
    
  );
}

export default RegistrationForm;

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import {
//   Grid,
//   TextField,
//   Button,
//   Typography,
//   Paper,
// } from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: '70vh',
//     backgroundImage: `url('https://www.sahajtherapy.com/files/Slider%2019c210e.jpg')`,
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'cover',
//     padding: theme.spacing(3),
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'relative', // new

//   },
//   paper: {
//     padding: theme.spacing(3),
//   },
//   form: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: theme.spacing(2),

//   },
//   buttonContainer: {
//     display: 'flex',
//     justifyContent: 'space-evenly',
//     marginTop: theme.spacing(2),
//   },
//   button: {
//     width: '100px',
//     height: '30px',
//   },
//   grid: {
//     position: 'absolute', // new
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//   }
// }));

// function RegistrationForm() {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <Grid item xs={12} md={6} className={classes.grid}>
//         <Paper className={classes.paper}>
//           <Typography variant="h5" align="center" gutterBottom>
//             Book An Appointment
//           </Typography>
//           <form className={classes.form}>
//             <TextField label="Patient Name" required />
//             <TextField label="Patient Mobile" required />
//             <TextField label="City" required />
//             <div className={classes.buttonContainer}>
//               <Button className={classes.button} variant="contained" color="primary">
//                 Save
//               </Button>
//               <Button className={classes.button} variant="contained" color="secondary">
//                 Discard
//               </Button>
//             </div>
//           </form>
//         </Paper>
//       </Grid>
//     </div>
//   );
// }

// export default RegistrationForm;
