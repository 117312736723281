import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';

const Test = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/register');
  }

  const handleData = () => {
    navigate('/avn');
  }
  const handleHome = () => {
    navigate('/');
  }
 const handleknee = () => {
  navigate('/knee')
 }

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand ><img src="./logo.svg" alt="logo" style={{width:"250px",height:"50px"}} onClick={handleHome}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '280px',marginLeft:"150px" }}
            navbarScroll
          >
            <Nav.Link onClick={handleHome}>Home</Nav.Link>
            <Nav.Link >About</Nav.Link>
            <Nav.Link>Science</Nav.Link>
            <NavDropdown title="Disease" id="navbarScrollingDropdown">
              <NavDropdown.Item >Osteoarthritis</NavDropdown.Item>
              <NavDropdown.Item  onClick={handleData}>AVN</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="For Patient" id="navbarScrollingDropdown">
              <NavDropdown.Item onClick={handleknee}>Knee Anatomy</NavDropdown.Item>
              <NavDropdown.Item>Patient Care Booklet</NavDropdown.Item>
              <NavDropdown.Item >FAQ</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link >Testimonials</Nav.Link>
            <Nav.Link >Contact</Nav.Link>
          </Nav>
          <Nav>
            <Button variant="primary" onClick={handleClick}>Book An Appointment</Button>  
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Test;