import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import HomeIcon from "@mui/icons-material/Home";

export default function Footer() {
  return (
    <Box
      style={{
        flexGrow: 1,
        backgroundImage: "url('../Footer.jpg')",
        opacity: "0.6",
      }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12} md={5}>
          <div>
            <img
              alt="footer-logo"
              src="https://www.sahajtherapy.com/files/SahajTherapyLogo_white.svg"
              style={{ width: "80%" }}
            />
            <p style={{ width: "80%", marginLeft: "50px", color: "white" }}>
              Providing Innovative Surgical procedure Sahaj<br></br>
              Therapy a Patented Technology for treatment of<br></br>
              Joint Pain. We are offering a patented technology called<br></br>
              Sahaj Therapy, which is an innovative surgical<br></br>
              procedure designed to treat joint pain. Our goal is<br></br>
              to provide individuals with primary knee care<br></br>
              services right at their doorstep,
            </p>
            <ul
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
                marginLeft: "40px",
                padding: "0",
              }}
            >
              <li style={{ listStyleType: "none", padding: "10px" }}>
                <a
                  href="https://www.facebook.com/kneexpert"
                  target="_blank"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  facebook
                </a>
              </li>
              <li style={{ listStyleType: "none", padding: "10px" }}>
                <a
                  href="https://www.instagram.com/knee_xpert/"
                  target="_blank"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  instagram
                </a>
              </li>
              <li style={{ listStyleType: "none", padding: "10px" }}>
                <a
                  href="https://www.youtube.com/channel/UCSB7JsU3xintMSpyK089CpQ/videos"
                  target="_blank"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  youtube
                </a>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid div xs={12} sm={6} md={2}>
          <div style={{ marginTop: "40px" }}>
            <h3 style={{ marginLeft: "10%", color: "white" }}>Our Menu</h3>
            <ul style={{ marginLeft: "10%", color: "white" }}>
              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/aboutus"
                >
                  Home
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/aboutus"
                >
                  About
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/osteoarthritis"
                >
                  Osteoarthritis
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/avn"
                >
                  AVN
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/knee"
                >
                  Knee Anatomy
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/blog"
                >
                  Blogs
                </a>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid div xs={12} sm={6} md={2}>
          <div style={{ marginTop: "40px" }}>
            <h3 style={{ marginLeft: "10%", color: "white" }}>Health Care</h3>
            <ul style={{ marginLeft: "10%", color: "white" }}>
              <li>
                <a href="/" style={{ color: "white", textDecoration: "none" }}>
                  Contact Us
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  class="text-white"
                  href="/aboutus"
                >
                  Terms & Conditions
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  class="text-white"
                  href="/osteoarthritis"
                >
                  Policy
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  class="text-white"
                  href="/avn"
                >
                  Disclaimer
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  class="text-white"
                  href="/knee"
                >
                  FAQ's
                </a>
              </li>

              <li>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  class="text-white"
                  href="/blog"
                >
                  Blogs
                </a>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid div xs={12} md={3}>
          <div style={{ marginTop: "40px" }}>
            <h3 style={{ marginLeft: "10%", color: "white" }}>Contact us</h3>
            <ul
              style={{
                marginLeft: "10%",
                paddingInlineStart: "0",
              }}
            >
              <li
                style={{
                  textAlign: "start",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "white", marginRight: "5px" }}>
                  <MailIcon />
                </span>
                <a
                  href="mailto:info@kneexpert.in"
                  title="Email"
                  style={{ textDecoration: "none" }}
                >
                  info@sahajtherapy.com
                </a>
              </li>
              <br />
              <li
                style={{
                  textAlign: "start",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "white", marginRight: "5px" }}>
                  <CallIcon />
                </span>
                <a
                  style={{ textDecoration: "none" }}
                  href="tel:82230-83555"
                  title="Call"
                >
                  0731-3525355
                </a>
              </li>
              <br />
              <li
                style={{
                  textAlign: "start",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "white", marginRight: "5px" }}>
                  <HomeIcon />
                </span>{" "}
                15/2, behind Hotel Surya, South Tukoganj, Indore, Madhya Pradesh
                452001
              </li>
              <li style={{ textAlign: "start" }}>
                <iframe
                  style={{
                    width: "320px",
                    height: "230px",
                    marginLeft: "20px",
                  }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.2080880296094!2d75.87864241430749!3d22.720505785106727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fdeb30d54b4f%3A0x82284869f0aa6b8d!2sSahaj%20Therapy!5e0!3m2!1sen!2sin!4v1679579776995!5m2!1sen!2sin"
                ></iframe>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
      <Box>
        <p style={{ fontSize: "13px", marginLeft: "45px", color: "white",marginTop:"155px" }}>
          Disclaimer: The information in this website is not intended or implied
          as a substitute for professional medical advice, diagnosis or
          treatment. All material, including text, graphics, images and
          information contained in this website is for information purposes
          only, as with any medical treatment, individual results may vary
          depending on your age, weight, level of illness, level of action and
          other factors. For more information, contact your orthopaedic surgeon
          or physiotherapist.
        </p>
      </Box>
      <Box>
        <Box
          style={{
            backgroundColor: "#A7C7E7",
            textAlign: "center",
            color: "white",
            marginLeft: "3%",
            marginRight: "3%",
          }}
        >
          Sahaj Therapy Copyright ©2023 All rights reserved
        </Box>
      </Box>
    </Box>
  );
}
